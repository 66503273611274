@import "../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-notifications/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-angular-grids/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-angular-inputs/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-icons/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-richtexteditor/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-layouts/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-grids/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-treegrid/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-gantt/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-angular-calendars/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-angular-dropdowns/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-angular-grids/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-angular-dropdowns/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-angular-lists/styles/bootstrap5.css";

// Override Bootstrap variables
$grid-breakpoints: (
  xs: 0,
  sm: 430px,
  md: 700px,
  lg: 1000px,
  xl: 1700px,
);

@import "../node_modules/bootstrap/scss/bootstrap.scss";

/* EXECUTION PROGRESS MAP */
$grey: #323232;
$grey2: #dfe3e4;
$blue: #2183dd;
$green: #009900;
$white: #fff;

.progress-map {
  position: relative;
  display: flex;
}

.progress-track {
  position: absolute;
  top: 15px;
  width: 100%;
  height: 5px;
  background: $grey;
  z-index: -1;
}

.progress-step {
  position: relative;
  width: 100%;
  font-size: 12px;
  text-align: center;
  z-index: 10;
}

.progress-clickable-area {
  width: auto;
  padding: 10px 0;
  cursor: pointer;
}

.progress-icon {
  font-size: 16px;
}

.lane-color-bar {
  width: 30%;
  margin: 0 auto;
  height: 5px;
  margin-top: 5px;
}

.progress-step-current {
  color: $blue !important;
  -webkit-text-stroke: 0.75px black;
}

.inprogress-step {
  color: $blue !important;
  -webkit-text-stroke: 0.75px black;
}

/* END EXECUTION PROGRESS MAP */

.instruction-title {
  p {
    margin: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
  }
}

.form-check-label {
  font-weight: bold;
}

.subsection-container {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  margin-bottom: 10px;
}

//Custom blazer button css class
.e-dark {
  background-color: #a19d9c;
}

.e-dark:hover {
  background-color: #bec2be;
}

.e-dark:focus,
.e-btn:active {
  background-color: #a19d9c;
}

.bg-blue {
  background-color: #0d2391 !important;
}

.bg-light-blue {
  background-color: lightblue;
  color: #000000;
}

.bg-gray {
  background-color: #f6f9fc !important;
}

.bg-soft-orange {
  background-color: #ffc000;
  color: black;
}

.bg-yellow {
  background-color: yellow;
  color: #000000;
}

.bg-red {
  background-color: #ce2029 !important;
  color: white;
}

.bg-green {
  background-color: #79a47f !important;
}

.bg-white {
  background-color: white !important;
  color: #000000;
}

.error-message {
  font-size: 14px;
  margin-top: 5px;
  color: #000000;
  border: 1px solid;
  margin: 10px auto;
  padding: 10px 10px 10px 50px;
  background-repeat: no-repeat;
  background-position: 10px center;
  background-color: #ffbaba;
  background-size: 25px;
  background-image: url("\\assets\\img\\errorIcon.png");
}

body {
  margin: 0;
  padding: 0;
  background: white;
  background: linear-gradient(0deg, #ffffff 0%, rgb(255, 255, 255) 100%) no-repeat center center fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.btn-primary {
  color: white;
}

.btn-secondary {
  color: black;
  background-color: #cbcdd8;
  border-color: #cbcdd8;
}

.btn-danger {
  color: white;
  background-color: #a92c23;
  border-color: #a92c23;
}

.e-danger {
  color: white;
  background-color: #a92c23;
  border-color: #a92c23;
}

.e-primary {
  color: white;
  background-color: #727c9e;
  border-color: #727c9e;
}

.page-header {
  color: white;
}

.dropdown-menu {
  background-color: #0d2391;
  border: 1px, solid, black;
  color: black;
}

.btn-success {
  background-color: #79a47f !important;
  color: rgb(255, 255, 255);
  border-color: #79a47f !important;
}

.btn-success:hover {
  border-color: #2a8137 !important;
  background-color: #2a8137 !important;
}

//GRID BUTTONS
.e-outline-primary {
  background-color: transparent;
  border: 2px solid #0d6efd;
  color: #0d6efd;
  padding: 2px 8px;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.e-outline-primary:hover {
  background-color: #0d6efd;
  border: 2px solid #0d6efd;
  color: white;
  padding: 2px 8px;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.e-outline-secondary {
  background-color: transparent;
  border: 2px solid gray;
  color: gray;
  padding: 2px 8px;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.e-outline-danger {
  background-color: transparent;
  border: 2px solid #dc3545;
  color: #dc3545;
  padding: 2px 8px;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.e-outline-danger:hover {
  background-color: #dc3545;
  border: 2px solid #dc3545;
  color: white;
  padding: 2px 8px;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.e-outline-success {
  background-color: transparent;
  color: #79a47f;
  border: 2px solid #79a47f;
  padding: 2px 8px;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.e-outline-success:hover {
  background-color: #79a47f;
  border: 2px solid #79a47f;
  color: white;
  padding: 2px 8px;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.e-outline-warning {
  background-color: transparent;
  border: 2px solid darkorchid;
  color: darkorchid;
  padding: 2px 8px;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.e-outline-warning:hover {
  background-color: darkorchid;
  border: 2px solid darkorchid;
  color: white;
  padding: 2px 8px;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.e-outline-black {
  background-color: transparent;
  border: 2px solid rgb(0, 0, 0);
  color: rgb(0, 0, 0);
  padding: 2px 8px;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.e-outline-black:hover {
  background-color: rgb(0, 0, 0);
  border: 2px solid rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  padding: 2px 8px;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

//END GRID BUTTONS

.add-button:hover {
  color: green;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

/* Default scrollbar styles */
::-webkit-scrollbar {
  height: 5px;
  width: 6px;
  border: 1px solid #000000;
}

/* Default scrollbar thumb styles */
::-webkit-scrollbar-thumb {
  background-color: #0d2391;
  border-radius: 10px;
  cursor: -webkit-grab;
  cursor: grab;
}

/* Default scrollbar thumb hover styles */
::-webkit-scrollbar-thumb:hover {
  background-color: #007bff;
  cursor: -webkit-grab;
  cursor: grab;
}

/* Default scrollbar track styles */
::-webkit-scrollbar-track {
  background-color: #e9eaf3;
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #0d2391 #f0f0f0;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) and (max-height: 1024px) {
  /* Adjust the height of the scrollbar for smaller screens */
  ::-webkit-scrollbar {
    height: 10px; /* Increase the height as needed */
  }

  /* Adjust any other styles for smaller screens if necessary */
}

.prettbox input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  background-color: white;
  border: 1px solid #5a5959;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  margin-top: auto;
  margin-bottom: auto;
}

/* Style for the checked state */
.prettbox input[type="checkbox"]:checked {
  background-color: #79a47f;
}

//Execution Mobile view
.mainCardMarginBottom {
  margin-bottom: 5%;
}

//Mobile view controls for execution
@media (max-width: 1350px) {
  .mobile-fixed-bottom {
    background-color: white;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
    margin-top: 15%;
    width: 100%;
    overflow-x: auto;
    padding: 20px;
    padding-bottom: 30px;
  }

  .mainCardMarginBottom {
    margin-bottom: 20%;
  }

  .btn-lg {
    margin-top: 10px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: x-large;
  }
}

@media (max-width: 500px) {
  button {
    font-size: small !important;
    word-wrap: none;
  }
}

//Date picker
.e-daterangepicker.e-popup.e-preset-wrapper,
.e-bigger.e-small .e-daterangepicker.e-popup.e-preset-wrapper {
  min-width: 625px;
  margin-left: 80px !important;
}

.was-validated .form-control:invalid:focus,
.form-control.ng-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.ng-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.form-control.ng-invalid.ng-dirty,
.custom-select.ng-invalid.ng-dirty {
  border-color: #dc3545;
}
